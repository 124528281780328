<!-- 系统设置 权限管理 角色管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        :selectable="true"
        :selectOnIndeterminate="false"
        @select="handleSelect"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:role:batchdel'" type="plain" @click="doDelete">
              批量删除
            </ykc-button>
            <ykc-button v-rbac="'sys:role:add'" type="plain" @click="add">新增</ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <AddOrEdit
      v-model="addEditDrawerVisible"
      :currentAccount="currentAccount"
      @finish="searchTableList"
      @close="addEditDrawerVisible = false" />
    <RelationDrawer
      v-model="relationDrawerVisible"
      :currentAccount="currentRelation"
      @finish="searchTableList"
      @close="relationDrawerVisible = false" />
  </scroll-layout>
</template>

<script>
  import { roleManage } from '@/service/apis';
  import { localGetItem, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import AddOrEdit from './AddOrEdit.vue';
  import RelationDrawer from './RelationDrawer.vue';

  export default {
    name: 'accountManageList',
    components: {
      AddOrEdit,
      RelationDrawer,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'roleName',
            label: '角色名称 ',
            placeholder: '请输入角色名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'roleType',
            label: '角色类型',
            placeholder: '请选择角色类型',
            configSet: {
              label: 'name',
              value: 'code',
            },
            data: localGetItem('dictionary')?.role_type || [],
          },
        ];
      },
    },
    watch: {
      // 关闭弹窗，当前操作数据需要清空，以防下次使用老数据
      addEditDrawerVisible: 'resetCurrentAccount',
    },
    data() {
      return {
        addEditDrawerVisible: false,
        currentAccount: null,
        relationDrawerVisible: false,
        currentRelation: null,
        ruleForm: {},
        showCwd: false,
        selectIds: [],
        tableData: [],
        tableColumns: [
          { label: '角色编码', prop: 'id', minWidth: '100px' },
          { label: '角色名称', prop: 'roleName', minWidth: '100px' },
          {
            label: '角色类型',
            prop: 'roleType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const [property] = this.role_type.filter(item => item.code === row.roleType);
                const roleType = property ? property.name : '——' || '——';
                return (
                  <div>
                    <span title={roleType}>{roleType}</span>
                  </div>
                );
              },
            },
          },
          { label: '修改时间', prop: 'updateTime', minWidth: '100px' },
          { label: '修改人', prop: 'updateBy', minWidth: '100px' },
        ],
        tableTitle: '角色管理',

        tableOperateButtons: [
          {
            enabled: () => code('sys:role:relation'),
            id: '1',
            text: '关联',
            handleClick: this.handleRelation,
          },
          {
            enabled: () => code('sys:role:edit'),
            id: '2',
            text: '编辑',
            handleClick: this.handleEdit,
          },
          {
            enabled: () => code('sys:role:detail'),
            id: '3',
            text: '详情',
            handleClick: (btn, { row }) => {
              console.log('详情', row);
              this.$router.push({
                path: '/systemSettings/powerManage/roleManage/details',
                query: { id: row.id },
              });
            },
          },
          {
            enabled: () => code('sys:role:batchdel'),
            id: '4',
            text: '删除',
            handleClick: (btn, { row }) => {
              this.deleteDialog(row);
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          roleName: '',
          roleType: '',
        },
        role_type: [],
      };
    },
    created() {
      this.role_type = localGetItem('dictionary')?.role_type || [];
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      // 关联
      handleRelation(_, { row }) {
        const [property] = this.role_type.filter(item => item.code === row.roleType);
        const roleTypeDes = property ? property.name : '——' || '——';
        this.currentRelation = {
          roleTypeDes,
          roleName: row.roleName,
          id: row.id,
        };
        this.relationDrawerVisible = true;
      },
      // 编辑
      handleEdit(_, { row }) {
        console.log('handleEdit', row);
        this.currentAccount = {
          roleType: row.roleType,
          roleName: row.roleName,
          id: row.id,
        };
        this.addEditDrawerVisible = true;
      },
      // 重置编辑信息
      resetCurrentAccount(val) {
        if (!val) {
          this.currentAccount = null;
        }
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        roleManage
          .queryUserRoleList(reqParams)
          .then(res => {
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        console.log('form', form);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 勾选
      handleSelect(selection, row) {
        console.log(selection, row);
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
        console.log(this.selectIds);
      },
      // 批量删除
      doDelete() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
          return;
        }
        this.deleteFun(this.selectIds);
      },
      deleteFun(reqParams) {
        const self = this;
        YkcDialog({
          showTitle: false,
          showSuccessIcon: false,
          type: 'success',
          dialogType: 'feedback',
          desc: '删除角色后，对应账号的角色权限将丢失，请谨慎操作！',
          onClose(done) {
            done();
          },
          onCancel(done) {
            done();
          },
          onConfirm(done) {
            roleManage.batchDelete(reqParams).then(res => {
              console.log(res);
              self.searchTableList();
              done();
            });
          },
        });
      },
      /** 新增 */
      add() {
        console.log('新增');
        this.addEditDrawerVisible = true;
      },
      // 删除
      deleteDialog(row) {
        this.deleteFun([row.id]);
      },
      // 修改密码
      changeShowCwd(val) {
        this.showCwd = val;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
