<!-- 系统设置 权限管理 角色管理 新增或编辑 -->
<template>
  <ykc-drawer
    :show="value"
    :title="drawerTitle"
    destroy-on-close
    @open="handleOpen"
    @update:show="$emit('input', $event)">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form ref="YkcForm" :model="model" :rules="rules">
        <ykc-form-item prop="roleType" label="角色类型" :bottomTip="bottomTip">
          <ykc-dropdown
            v-model="model.roleType"
            placeholder="请选择角色类型"
            :clearable="false"
            :disabled="this.currentAccount?.id"
            :data="roleTypeList"
            :configSet="configSet"
            :style="bottomTip ? { marginBottom: '-20px' } : {}"
            @change="changeType"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="角色名称" prop="roleName">
          <ykc-input
            v-model="model.roleName"
            maxlength="50"
            placeholder="请输入角色名称"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="角色功能" prop="menuIdList">
          <ykc-tree
            :data="treeData"
            :props="treeProps"
            ref="YkcTree"
            @check-change="handleTreeCheckChange"></ykc-tree>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import { localGetItem } from '@/utils';
  import regexpObj from '@/utils/regexp';
  import { roleManage, sysDict } from '@/service/apis';

  const initModel = () => ({
    roleType: '',
    roleName: '',
    menuIdList: [],
  });

  export default {
    props: ['value', 'currentAccount'],
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.onAddDrawerCancel();
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        bottomTip: '',
        model: initModel(),
        drawerTitle: '角色新增',
        roleTypeList: localGetItem('dictionary')?.role_type || [],
        configSet: {
          label: 'name',
          value: 'code',
        },
        rules: {
          roleType: [{ required: true, message: '请输入选择角色类型', trigger: 'blur' }],
          roleName: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('角色名称'),
            },
          ],
          menuIdList: [
            { required: true, type: 'array', message: '请选择菜单权限', trigger: 'blur' },
          ],
        },
        treeData: [],
        fleetData: [],
        selectMenuList: [],
        treeProps: {
          label: 'menuName',
          children: 'subNodes',
          disabled: 'disabled',
        },
      };
    },
    methods: {
      // 角色类型切换
      changeType(val) {
        this.getMenuTree(val);
      },
      // 详情
      getDetail() {
        return roleManage.detail(this.currentAccount.id).then(res => {
          this.selectMenuList = res?.selectMenuList || [];
        });
      },
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        if (this.currentAccount) {
          this.drawerTitle = '角色编辑';
          this.bottomTip = '温馨提示：调整角色类型后，角色功能会刷新，并且选择项会清空';
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentAccount[key];
          });
          await this.getDetail();
          this.getMenuTree(this.currentAccount.roleType);
        } else {
          this.drawerTitle = '角色新增';
          this.bottomTip = '';
          this.treeData = [];
        }
      },
      // 菜单树初始化选中状态
      handleTreeMounted() {
        if (this.currentAccount) {
          const keys = this.selectMenuList;
          console.log('handleTreeMounted', keys);
          this.model.menuIdList = [...keys];
          this.$refs.YkcTree.setCheckedKeys(keys, true);
        }
      },
      // 菜单树变化
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.menuIdList = keys;
      },
      // 菜单树初始化
      async getMenuTree(useRange = '1') {
        this.treeData = [];
        this.model.menuIdList = [];
        sysDict.querySupportMenuList({ useRange }).then(res => {
          this.treeData = res || [];
          if (useRange === this.currentAccount?.roleType && this.treeData.length > 0) {
            this.$nextTick(() => {
              this.handleTreeMounted();
            });
          }
        });
      },
      /**
       * 这里只需要处理 按钮节点选择导致最后一级菜单id被tree丢失的问题
       */
      deepLoopTree(menuIds, item, parent) {
        if (
          menuIds.indexOf(item.id) !== -1 &&
          item.menuType === 2 &&
          menuIds.indexOf(parent.id) === -1
        ) {
          menuIds.push(parent.id);
        }
        if (item.subNodes && item.subNodes.length) {
          item.subNodes.forEach(cd => {
            this.deepLoopTree(menuIds, cd, item);
          });
        }
      },
      getAllMenuIdList() {
        const menuIdList = [...this.model.menuIdList];
        this.treeData.forEach(item => {
          this.deepLoopTree(menuIdList, item);
        });
        return menuIdList;
      },
      // 保存
      beforeEnsure() {
        this.$refs.YkcForm.validate(async valid => {
          if (valid) {
            const menuIdList = this.getAllMenuIdList();
            const reqParams = {
              roleType: this.model.roleType,
              roleName: this.model.roleName,
              menuIdList,
            };
            if (this.currentAccount) {
              reqParams.roleId = this.currentAccount.id;
            }
            roleManage.save(reqParams).then(() => {
              this.$emit('close');
              this.$emit('finish');
            });
          }
        });
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            this.$emit('close');
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
