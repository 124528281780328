<!-- 系统设置 权限管理 角色管理 关联账号 -->
<template>
  <ykc-drawer
    :show="value"
    destroy-on-close
    title="关联账号"
    @update:show="$emit('input', $event)"
    @open="handleOpen">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form ref="YkcForm" :model="model" :rules="rules">
        <ykc-form-item label="角色类型">
          {{ model.roleTypeDes }}
        </ykc-form-item>

        <ykc-form-item label="角色名称">
          {{ model.roleName }}
        </ykc-form-item>

        <ykc-form-item label="关联账号" prop="menuIdList">
          <ykc-tree
            :data="treeData"
            :props="treeProps"
            ref="YkcTree"
            @check-change="handleTreeCheckChange"></ykc-tree>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import { roleManage } from '@/service/apis';

  const initModel = () => ({
    roleTypeDes: '0',
    roleName: '',
    menuIdList: [],
  });

  export default {
    props: ['currentAccount', 'value'],
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.onAddDrawerCancel();
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        model: initModel(),
        rules: {
          menuIdList: [
            { required: true, type: 'array', message: '请选择关联账号', trigger: 'blur' },
          ],
        },
        fleetData: [],
        treeData: [],
        treeProps: {
          label: 'accountNo',
          children: 'subNodes',
          disabled: 'disabled',
        },
      };
    },
    methods: {
      // 抽屉展示
      handleOpen() {
        this.model = initModel();
        if (this.currentAccount) {
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentAccount[key];
          });
        }
        this.getMenuTree();
      },
      // 菜单树变化
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.menuIdList = keys;
      },
      // 菜单树初始化
      async getMenuTree() {
        this.treeData = [];
        this.model.menuIdList = [];
        roleManage.relation(this.currentAccount.id).then(res => {
          this.treeData = res?.accountList || [];
          if (res.selectAccountIdList && res.selectAccountIdList.length > 0) {
            this.model.menuIdList = res.selectAccountIdList;
            this.$nextTick(() => {
              this.$refs.YkcTree.setCheckedKeys(res.selectAccountIdList, true);
            });
          }
        });
      },
      // 保存
      beforeEnsure() {
        this.$refs.YkcForm.validate(async valid => {
          if (valid) {
            roleManage
              .relationSave({
                roleId: this.currentAccount.id,
                accountIdList: this.model.menuIdList,
              })
              .then(() => {
                this.$emit('close');
                this.$emit('finish');
              });
          }
        });
      },
      // 取消
      onAddDrawerCancel() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            this.$emit('close');
            done();
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
