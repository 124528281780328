<!-- 系统设置 权限管理 角色管理 角色详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="80">
        <div slot="header" class="clearfix">
          <span class="title">角色详情</span>
          <ykc-button type="plain" v-rbac="'sys:role:detailexportaccount'" @click="doExportFile">
            导出
          </ykc-button>
        </div>
        <ykc-detail-item-plus label="角色名称">
          {{ formData.roleName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="角色类型">
          {{ roleTypeFormat(formData.roleType) }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="可用功能" vAlign="flex-start">
          <ykc-tree
            :data="menuList"
            :props="treeProps"
            selectType="none"
            class="detailTree"
            :searchable="false"></ykc-tree>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button type="plain" v-rbac="'sys:role:detailexportdata'" @click="doExportFile2">
            导出
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { roleManage } from '@/service/apis';
  import { localGetItem, offlineExport } from '@/utils';

  export default {
    name: 'SpecialSellingPriceDetail',
    components: {},
    data() {
      return {
        tableData: [],
        tableColumns: [
          { label: '账号', prop: 'accountNo' },
          { label: '姓名', prop: 'accountName' },
          { label: '修改时间', prop: 'modifiedTime' },
          { label: '修改人', prop: 'modifiedBy' },
          {
            label: '状态',
            prop: 'status',
            scopedSlots: {
              default: ({ row }) => {
                let title = '——';
                if (row.status === '0') {
                  title = '启用';
                } else if (row.status === '1') {
                  title = '停用';
                }
                return (
                  <div>
                    <span title={title}>{title}</span>
                  </div>
                );
              },
            },
          },
        ],
        tableTitle: '账号管理',
        tableOperateButtons: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        menuList: [],
        treeProps: {
          label: 'menuName',
          children: 'subNodes',
          disabled: 'disabled',
        },
        formData: {},
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        id: '',
      };
    },
    mounted() {
      this.id = this.$route.query.id;
      this.getDetail();
      this.getListData();
    },
    methods: {
      roleTypeFormat(row) {
        const role_type = localGetItem('dictionary')?.role_type || [];
        const [property] = role_type.filter(item => item.code === row);
        return property ? property.name : '——' || '——';
      },
      // 导出 角色详情
      doExportFile() {
        console.log('doExportFile');
        offlineExport({
          downloadType: 'role_menu',
          jsonNode: {
            roleId: this.id,
          },
        });
      },
      // 导出 账号管理
      doExportFile2() {
        console.log('doExportFile2');
        offlineExport(
          {
            downloadType: 'role_accounts',
            jsonNode: {
              roleId: this.id,
            },
          },
          this.total
        );
      },
      getDetail() {
        roleManage.detail(this.id).then(res => {
          console.log(res);
          this.formData = res;
          this.menuList = res.menuList;
        });
      },
      getListData() {
        const reqParams = {
          id: this.id,
          current: this.currentPage,
          size: this.pageSize,
        };
        roleManage.detailAccountList(reqParams).then(res => {
          this.tableData = res?.records || [];
          this.total = res?.total;
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getListData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageSize = size;
        this.getListData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    .clearfix {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > button {
        margin-top: 14px;
      }
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
    }
  }
</style>
